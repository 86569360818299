import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/chatBot",
  },
  {
    path: "/ChatBot",
    name: "ChatBot",
    component: () => import("../views/ChatBot/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // 或者使用 sessionStorage.clear() 清除所有
  sessionStorage.clear();
  next();
});
export default router;
