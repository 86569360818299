import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    policyInfo:{}
  },
  getters: {
    // 获取政灵通跳转的数据
    GET_POLICY_INFO({ policyInfo }) {
      return policyInfo
    },
    NavigateToXiaoWei(state) {
      return state.navigateToXiaoWei
    }
  },
  mutations: {
    // 设置政灵通跳转的数据（从地址栏获取）
    SET_POLICY_INFO(state, info) {
      state.policyInfo = info
    },
  },
  actions: {
  },
  modules: {
  }
})
