<template>
  <div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    onClickLeft() {
      this.$router.back()
    },
  },
}
</script>

<style scoped lang="less"></style>
